<template>
    <div
        v-if="campusBlocks && campusBlocks.length"
        class="campus-blocks"
    >
        <template v-for="(campusBlock, key) in campusBlocks">
            <template v-if="campusBlock.linkSingle[0]">
                <NavLink
                    :key="key"
                    :type="mapType(campusBlock.linkSingle[0].__typename)"
                    :url="mapURI(campusBlock.linkSingle[0])"
                    :blank="campusBlock.linkSingle[0].openInNewWindow"
                    class="campus-block"
                    data-ga-category="campus-block-click"
                    :data-ga-action="mapURI(campusBlock.linkSingle[0])"
                    :data-ga-label="$route.path"
                    data-ga4-event="campus_block_click"
                >
                    <ImageAsset
                        v-if="campusBlock.image[0]"
                        class="campus-block__image"
                        :image="campusBlock.image[0]"
                        :max="980"
                        sizes="100vw"
                    />
                    <div class="campus-block__content">
                        <div class="campus-block__title">{{ campusBlock.title }}</div>
                        <div class="campus-block__label button--label"><span>{{ campusBlock.linkSingle[0].header }}</span><BaseIcon icon="arrow-right" /></div>
                    </div>
                </NavLink>
            </template>
        </template>
    </div>
</template>

<script>
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],

    props: {
        campusBlocks: {
            type: Array,
            required: true,
            default: () => { return []; }
        }
    }
};
</script>

<style lang="less" src="./CampusBlocks.less"></style>
